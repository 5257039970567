<template>
  <div class="wx-app-config-desc">
    <div v-if="this.appConfigId > 0">
      <p class="config-item-title">配置说明</p>
      <div class="config-desc">
        <div class="callback-url">
          回调地址:
          <el-tooltip effect="dark" content="点击复制" placement="bottom">
            <pre @click="doCopy(appConfigDesc.callbackUrl)">{{
              appConfigDesc.callbackUrl
            }}</pre>
          </el-tooltip>
        </div>
        <div class="ip-white-list">
          IP白名单:
          <el-tooltip effect="dark" content="点击复制" placement="bottom">
            <pre @click="doCopy(appConfigDesc.ipWhiteList)">{{
              appConfigDesc.ipWhiteList
            }}</pre>
          </el-tooltip>
        </div>
      </div>
      <div>
        <p class="config-item-title">配置方法</p>
        <div class="config-desc">
          <ol>
            <li>
              复制上方回调地址，填写到微信后台的服务器地址(URL)字段中，点击提交，验证通过即可
            </li>
            <li>
                复制上方IP白名单,填写到微信后台的IP白名单中即可
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div v-else class="div-empty">
      <el-empty description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: "wx-app-config-desc",
  props: {
    appConfigId: {
      type: Number,
    },
  },
  data() {
    return {
      appConfigDesc: {},
    };
  },
  methods: {
    async doCopy(text) {
      try {
        await this.$copyText(text);
        this.$message.success({
          title: "提示",
          message: "复制成功",
          duration: 800,
        });
      } catch (e) {
        console.log(e);
        this.$notify.error({
          title: "错误",
          message: "复制失败，可能您的浏览器不支持，请手动选择复制",
          duration: 1500,
        });
      }
    },
    async refreshConfigDesc(id) {
      if (id > 0) {
        this.appConfigDesc = await this.$http.doApi("fw-wx-app-getConfigDesc", {
          id: id,
        });
      } else {
        this.appConfigDesc = {};
      }
    },
  },
  watch: {
    appConfigId: {
      immediate: true,
      handler(val) {
        this.refreshConfigDesc(val);
      },
    },
  },
};
</script>

<style lang="less">
.wx-app-config-desc {
  pre {
    display: inline-block;
    border: 1px solid #cccccc;
    padding: 3px 5px;
    border-radius: 5px;
    background-color: #f3f3f3;
    cursor: pointer;
  }

  .config-item-title {
    margin: 0.5rem 0.2rem 0.2rem 0.2rem;
  }

  .config-desc {
    border: 1.5px #cccccc dotted;
    border-radius: 5px;
  }

  .div-empty {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>